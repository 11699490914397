import styled from "styled-components"
import { grayBackground } from "mill/utils/colors"
import zIndex from "shared/utils/zIndexLevels"

import tooltip from "mill/components/Tooltip/info.png"
import tooltipHover from "mill/components/Tooltip/info-hover.png"

export const Container = styled.div`
  background: white;
  border-radius: 3px;
  margin-top: 1.5rem;
  position: relative;
  color: #909090;
  display: flex;
  ${props => props.flex && `flex: 1 0 auto;`} flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-width: 100%;

  &:hover .hover {
    color: #909090;
    transition: opacity 0.2s ease-in;
    opacity: 1;
  }
`

export const PassThrough = styled.div`
  flex: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndex.base + 1};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Heading = styled.div`
  padding: 1.5rem 2rem 1rem 2rem;
  position: relative;
  z-index: ${zIndex.base + 2};
  display: flex;
`

export const Title = styled.h3`
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  font-weight: 600;
  color: #909090;
  margin: 0;
  flex: 1;
  line-height: 3rem;
`

export const Body = styled.div`
  padding: 0 2rem 2rem 2rem;
  ${props => props.flex && `flex: 1 0 auto; justify-content: center;`}
  display: ${props => (props.flex ? `flex` : `block`)};
  max-width: 100%;
  position: relative;
`

export const Link = styled.a`
  pointer-events: auto;
  z-index: ${zIndex.base + 3};
  border-radius: 3px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  top: 0.4rem;
  left: 1rem;

  &:hover > .padded-hover-img {
    background-image: url(${tooltipHover});
    transition: 0.2s all ease-in;
    background-color: ${props => props.theme.colors.primary};
    z-index: ${zIndex.base + 3};
  }
`

export const Icon = styled.span`
  margin-top: -2rem;
  border-radius: 3px;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: ${grayBackground};
  background-image: url(${tooltip});
  background-position: center center;
  background-size: 4px 9px;
`

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  padding: 2rem;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.3em;
  z-index: ${zIndex.base + 1};
  opacity: ${props => (props.hovered ? 1 : 0)};
`

export const Filters = styled.div``
