import { grayBackground } from "mill/utils/colors"
import { darken } from "polished"
import styled from "styled-components"
import { sm } from "mill/utils/breakpoints"

export const SubMenu = styled.div`
  background: white;
  border-bottom: 1px solid ${darken(0.1, grayBackground)};
  ul {
    list-style: none;
  }
`

export const UpperNav = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  align-items: flex-end;
`

export const DashLinks = styled.ul`
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 5rem;

  a {
    display: flex;
    align-items: center;
    height: 5rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 600;
    margin: 0;
    padding: 0 2rem;
    border-bottom: 2px solid white;
    color: #909090;
    cursor: pointer;
    height: 100%;
    white-space: nowrap;
    &:hover {
      border-bottom: 2px solid ${props => props.theme.colors.primary};
    }
  }

  @media ${sm} {
    display: flex;
  }
`

export const ListItem = styled.li`
  margin: 0;
  padding: 0;
  display: flex;
  height: 100%;
  align-items: center;

  ${props =>
    props.active &&
    `
      a {
        border-bottom: 2px solid ${props.theme.colors.primary};
      }
    `};
`

export const CampaignHeader = styled.div`
  @media ${sm} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`

export const TeamHeader = styled.div`
  a {
    color: ${props => props.theme.colors.primary};
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    font-size: 1.4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 1.3rem 0;
  }

  i {
    padding-left: 1rem;
    transform: rotate(180deg);
  }

  @media ${sm} {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const H1 = styled.h1`
  text-transform: uppercase;
  color: #707070;
  font-family: Raleway;
  font-weight: 600;
  font-size: 2rem;
  letter-spacing: 2px;
  padding-right: 2rem;

  @media ${sm} {
    ${props =>
      props.noTopMargin &&
      `
      margin-top: 0;
    `}
  }

  span {
    color: #adadad;
  }
`
