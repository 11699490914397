import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const ActiveTitle = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  font-weight: 600;
  margin: 0;
  padding: 0 2rem;
  border-bottom: 2px solid white;
  position: relative;
  min-width: 32rem;
  height: 5rem;

  @media ${md} {
    min-width: 45rem;
  }
`

export const Menu = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  position: relative;
`

export const Arrow = styled.li`
  height: 5rem;
  width: 5rem;
  border-right: 1px solid #eee;
  color: white;
  line-height: 5rem;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.16);
`

export const SubMenu = styled.ul`
  display: ${props => props.visible ? "block" : "none"};
  position: absolute;
  overflow: hidden;
  box-shadow: ${props =>
    props.visible ? "0 2px 2px rgba(0, 0, 0, 0.2)" : "none"};
  max-height: ${props => (props.visible ? "1000px" : "0")};
  transition: max-height 0.2s
    ${props => (props.visible ? "ease-in" : "ease-out")};
  margin: 0;
  padding: 0;
  list-style: none;
  min-width: 30rem;
  top: 5rem;
  left: 0;
  right: 0;
  z-index: ${zIndex.menu - 1};
  max-height: 40rem;
  overflow-y: scroll;
`

export const SubMenuItem = styled.li`
  margin: 0;
  padding: 0 2rem;
  line-height: 5rem;
  background: white;
  border-top: 1px solid #eee;
  border-left: 4px solid white;
  font-weight: ${props => (props.notClickable ? "normal" : "bold")};

  ${props =>
    !props.notClickable &&
    `
    &:hover {
      border-left: 4px solid ${props.theme.colors.primary};
      cursor: pointer;
    }
  `} a {
    display: block;
    color: #535353;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
